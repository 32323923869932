import NewSubnet from '@/layouts/Stack/components/NewSubnet';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  data() {
    return {
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
  },
  methods: {
    newSubnet: function (instance, instanceName) {
      const that = this;
      return new Promise(() => {
        const selfName = 'NewSubnet';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewSubnet,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instanceName = instance.name;
              instance = data;
              // console.log(instance);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.addNewSubnet(instance.net, instance, instanceName).then(async data => {
                    // console.log(data);
                    if (data) {
                      await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
                      await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
                      this.$modals.close();
                      this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
                      this.showResModal('Подсеть успешно создана.');
                    } else that.$modals.close({ name: 'NewSubnet' });
                  });
                  // .catch(error => {
                  //   this.showError(error);
                  //   console.log('111');
                  //   this.$modals.close({ name: 'NewSubnet' });
                  // });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewNetwork(payload) {
      return this.$store.dispatch('moduleStack/createNetwork', payload).catch(e => {
        this.showError(e);
      });
    },
    addNewSubnet(id, payload, name) {
      const params = {
        id: id,
        ...payload,
      };
      return this.$store.dispatch('moduleStack/createSubnet', params).catch(e => {
        this.showError(e, name);
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
